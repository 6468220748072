<template>
  <div class="view pa24">
    <div class="d-flex align-items-center">
      <h3 class="cA1 mr10">
        已回款<span style="color: rgb(81, 203, 205)">{{
          moneyBack | formatMoney
        }}</span
        >元，待回款{{ noMoneyBack | formatMoney }}元
      </h3>
      <span>注：确认收货后回款才到账</span>
    </div>
    订单状态：
    <el-radio
      v-model="radioValue"
      class="mr10 mt20"
      v-for="(item, index) in options"
      style="margin: 0"
      :label="item.value"
      :key="index"
      border
      size="medium"
      >{{ item.label }}</el-radio
    >
    <el-button type="primary" @click="selectCompanyTransferList"
      >搜索</el-button
    >
    <commonTable
      :tableData="tableData"
      :loading="loading"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      :currentPage="currentPage"
      :total="total"
    >
      <template v-slot:table>
        <el-table-column
          prop="ordersNo"
          align="center"
          label="订单编号"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{scope.row.ordersNo||'--'}}
          </template>
        </el-table-column>
        <el-table-column prop="goodsName" align="center" show-overflow-tooltip label="商品名称" >
          <template slot-scope="scope">
            {{scope.row.goodsName||'--'}}
          </template>
        </el-table-column>
        <el-table-column prop="payPrice" align="center" label="付款金额（元）">
          <template slot-scope="scope">
            {{ scope.row.payPrice ? (scope.row.payPrice / 100).toFixed(2) : 0 }}
          </template>
        </el-table-column>
        <el-table-column prop="royaltyMoney" align="center" label="分销佣金">
          <template slot-scope="scope">
            {{
              scope.row.royaltyMoney
                ? (scope.row.royaltyMoney / 100).toFixed(2)
                : 0
            }}
          </template>
        </el-table-column>
        <el-table-column
          prop="transferAmount"
          align="center"
          label="实收金额（元）"
        >
          <template slot-scope="scope">
            {{
              scope.row.transferAmount
                ? (scope.row.transferAmount / 100).toFixed(2)
                : 0
            }}
          </template>
        </el-table-column>
        <el-table-column prop="level" align="center" label="分销等级" >
          <template slot-scope="scope">
          {{scope.row.level||'--'}}
          </template>
        </el-table-column>
        <el-table-column prop="payTime" align="center" show-overflow-tooltip label="付款时间">
          <template slot-scope="scope">
            {{ scope.row.payTime | getDataTimeSec }}
          </template>
        </el-table-column>
        <el-table-column prop="nickeName" align="center" show-overflow-tooltip label="付款人姓名" >
          <template slot-scope="scope">
            {{scope.row.nickeName||'--'}}
          </template>
        </el-table-column>
        <el-table-column prop="phone" align="center" label="联系方式" />
        <el-table-column prop="orderStatus" align="center" label="订单状态">
          <template slot-scope="scope">
            <span :style="{ color: scope.row.state == 0 ? '#ff0a0a' :'#51CDCB' }">{{
              scope.row.state != 0 ? "已结算" : "结算失败"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <customPopconfirm
              confirm-button-text="确定"
              cancel-button-text="取消"
              @confirm="changeState(scope.row)"
              icon="el-icon-info"
              icon-color="red"
              v-if="scope.row.state == 0"
              title="是否手动更改订单状态由原有的结算失败改为已结算"
            >
              <el-button slot="reference" type="text">更改状态</el-button>
            </customPopconfirm>
            <template v-else>-</template>
          </template>
        </el-table-column>
        <el-table-column align="center" show-overflow-tooltip label="备注">
          <template slot-scope="scope">
            <span>{{ scope.row.state == 2 ? "手动修改为“已结算”" : scope.row.state == 1?'线上提现成功':'-' }}</span>
          </template>
        </el-table-column>
      </template>
    </commonTable>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import customPopconfirm from "@/components/common/customPopconfirm";
import { getDataTimeSec } from "@/utils";
import { selectCompanyTransferList, updateCompanyTransferState } from "@/api/distribution";
export default {
  name: "accountTransfer",
  components: {
    commonTable,
    customPopconfirm
  },
  data() {
    return {
      options: [
        { value: "", label: "全部订单" },
        { value: "0", label: "结算失败" },
        { value: "1", label: "已结算" },
      ],
      radioValue: "",
      tableData: [],
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      pageSize2: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
      moneyBack: 0,
      noMoneyBack: 0,
    };
  },
  filters: {
    getDataTimeSec(val) {
      return getDataTimeSec(val);
    },
    formatMoney(val) {
      return val ? (val / 100).toFixed(2) : 0;
    },
  },
  created() {
    this.selectCompanyTransferList();
  },
  methods: {
    async selectCompanyTransferList() {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        state: this.radioValue,
      };
      try {
        this.loading = true;
        const result = await selectCompanyTransferList(data);
        this.loading = false;
        const {
          pageInfo: { total, list },
          moneyBack,
          noMoneyBack,
        } = result?.data;
        this.tableData = list;
        this.total = total;
        this.moneyBack = moneyBack;
        this.noMoneyBack = noMoneyBack;
      } catch (error) {
        this.loading = false;
        this.tableData = [];
        console.log(error);
      }
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.selectCompanyTransferList()
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.selectCompanyTransferList()
    },
   async changeState(val){
     try {
       await updateCompanyTransferState({transferId:val.transferId});
       this.selectCompanyTransferList();
     } catch (error) {
       console.log(error)
     }
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
